import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import styles from './SunsetBanner.module.scss';
import CloseIcon from './CloseIcon.svg';
import Link from '../../Base/LinkLocalised/LinkLocalised';
import { FormattedMessage } from 'react-intl';
import Image from 'next/image';

const SunsetBanner = (props) => {
    const { isClosable, isHidden, version } = props;

    const initialState = {
        visible: false,
        open: false
    };

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    );

    useEffect(() => {
        const hidden = localStorage.getItem('sunsetBanner3');

        if (hidden === 'true' && isClosable) return;
        setState({ visible: true });
        setTimeout(() => {
            setState({ open: true });
        }, 800);
    }, [state.visible, isClosable]);

    const handleHideClick = () => {
        localStorage.setItem('sunsetBanner3', 'true');
        setState({ visible: false });
    };

    const renderCloseButton = () => {
        return (
            <button className={`btn btn-icon ${styles.close}`} onClick={handleHideClick}>
                <Image src={CloseIcon} alt="close" layout="fill" />
            </button>
        );
    };

    const renderVersion = () => {
        if (version === 'pricingPlan') {
            return (
                <>
                    <p>
                        <FormattedMessage
                            defaultMessage="We're sad to announce the closure of our stand-alone operations as of <strong>September 27, 2024</strong>. The Smartmockups team will continue delivering the first-class mockup experience you love until the shutdown date. Learn what this means for you <Link>here</Link>."
                            values={{
                                strong: (...chunks) => <strong>{chunks}</strong>,
                                Link: (...chunks) => (
                                    <Link to="https://help.smartmockups.com/article/99-closing-smartmockups">
                                        {chunks}
                                    </Link>
                                )
                            }}
                        />
                    </p>
                </>
            );
        }

        if (version === 'pricingModal') {
            return (
                <>
                    <p>
                        <FormattedMessage
                            defaultMessage="We're sad to announce the closure of our stand-alone operations as of <strong>September 27, 2024</strong>."
                            values={{
                                strong: (...chunks) => <strong>{chunks}</strong>
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            defaultMessage="The Smartmockups team will continue delivering the first-class mockup experience you love until the shutdown date. Learn what this means for you <Link>here</Link>."
                            values={{
                                Link: (...chunks) => (
                                    <Link to="https://help.smartmockups.com/article/99-closing-smartmockups">
                                        {chunks}
                                    </Link>
                                )
                            }}
                        />
                    </p>
                </>
            );
        }

        if (version === 'setting') {
            return (
                <>
                    <p>
                        <FormattedMessage
                            defaultMessage="We're sad to announce the closure of our stand-alone operations as of <strong>September 27, 2024</strong>. The Smartmockups team will continue delivering the first-class mockup experience you love until the shutdown date. Learn what this means for you <Link>here</Link>."
                            values={{
                                strong: (...chunks) => <strong>{chunks}</strong>,
                                Link: (...chunks) => (
                                    <Link to="https://help.smartmockups.com/article/99-closing-smartmockups">
                                        {chunks}
                                    </Link>
                                )
                            }}
                        />
                    </p>
                </>
            );
        }

        return (
            <>
                <p>
                    <FormattedMessage
                        defaultMessage="We're sad to announce the closure of our stand-alone operations as of <strong>September 27, 2024</strong>."
                        values={{
                            strong: (...chunks) => <strong>{chunks}</strong>
                        }}
                    />
                </p>
                <p>
                    <FormattedMessage
                        defaultMessage="The Smartmockups team will continue delivering the first-class mockup experience you love until the shutdown date. Learn what this means for you <Link>here</Link>."
                        values={{
                            Link: (...chunks) => (
                                <Link to="https://help.smartmockups.com/article/99-closing-smartmockups">
                                    {chunks}
                                </Link>
                            )
                        }}
                    />
                </p>
            </>
        );
    };

    const shouldShowBanner = () => {
        return isHidden !== true && state.visible;
    };

    if (!shouldShowBanner()) return null;

    return (
        <div
            className={`${styles.wrapper} ${state.open ? styles.open : null} ${
                version ? styles.rounded : null
            }`}
        >
            <div>
                <div className={styles.center}>{renderVersion()}</div>

                {isClosable ? renderCloseButton() : null}
            </div>
        </div>
    );
};

SunsetBanner.propTypes = {
    isClosable: PropTypes.bool,
    isHidden: PropTypes.bool,
    version: PropTypes.oneOf(['pricingPlan', 'pricingModal', 'setting'])
};

export default SunsetBanner;
