import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

const useActions = (actions, ...filteredKeys) => {
	const dispatch = useDispatch();
	filteredKeys = filteredKeys.flat();
	if (filteredKeys.length === 0) {
		filteredKeys = Object.keys(actions);
	}
	return useMemo(() => {
		const dispatches = {};
		for (const key of filteredKeys) {
			const action = actions[key];
			dispatches[key] = (...args) => dispatch(action(...args));
		}
		return dispatches
	}, [dispatch, actions, ...filteredKeys])

}

export default useActions;